@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");

.service-card {
  position: relative;
  min-height: 230px;
  border: 1px solid #dee2e6;
  overflow: hidden;
  border-radius: 3px;
  user-select: none;
  cursor: pointer;
}

.service-card .body {
  padding: 10px;
}

.service-card .body .title {
  color: #2d2d2d;
  font-weight: 700;
}

.service-card .body .icon {
  margin-top: 40px;
  width: 55px;
  margin-bottom: 25px;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .service-card .body .icon {
    -webkit-transition: none;
    transition: none;
  }
}

.service-card .body .subtitle {
  position: absolute;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .service-card .body .subtitle {
    -webkit-transition: none;
    transition: none;
  }
}

.service-card:hover .body .icon {
  margin-top: 5px;
  width: 45px;
  margin-bottom: 15px;
}

.service-card:hover .body .subtitle {
  bottom: 7px;
  opacity: 1;
  visibility: visible;
}

/* .....service details......  */

.services_wrapper {
  padding: 2rem 0;
}

.service_details {
  width: 100%;
  margin: 4rem 0;
  padding: 0;
}

/* .container {

} */

.container__heading {
  padding: 0 1rem;
  color: #2d2d2d;
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container__heading .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.container__heading .subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/* .card-link {
   
} */

.card-link .card {
  max-width: 500px;
  min-height: 250px;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ced4da3c;
}

.card-link .card:hover {
  border-color: #e11d47;
}

.card__body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-container {
  width: 100%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content .title {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 0;
  text-transform: capitalize;
}

.content .info {
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

/* .....product section..... */

.product-section {
  margin-bottom: 1rem;
}

.product-container {
  width: 100vw;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-container .overlay {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-container .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.product-container .overlay img {
  width: 150%;
}

.product-container .container {
  position: absolute;
  padding: 2rem;
  z-index: 2;
}

.product-container .container h1 {
  font-size: 5rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  font-weight: 700;
}

.product-container .container p {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  font-weight: 400;
  text-transform: capitalize;
}

.product-container .container a {
  background: #e11d47;
  transition: all 0.6s;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}

.product-container .container a:hover {
  color: #fff;
  box-shadow: 0px 1px 5px 2.5px #e11d47b6;
}

.section-intro {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}
.section-intro__title {
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 10px;
}
.section-intro__subtitle {
  font-size: 15px;
  color: #2d2d2d;
  margin-bottom: 0;
}
.card-feature {
  min-height: 250px;
  padding: 30px 35px;
  border: 0;
  border-radius: 0;
  background: #faf9ff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.card-feature__icon {
  margin-bottom: 12px;
}
.card-feature__icon i,
.card-feature__icon span {
  font-size: 30px;
  color: #e11d47;
}
.card-feature__title {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.card-feature__subtitle {
  margin-bottom: 0;
}
.card-feature:hover {
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(33, 20, 106, 0.1);
}

@media (max-width: 992px) {
  /* .....product section..... */

  .product-container .container {
    padding: 2rem;
  }

  .product-container .container h1 {
    font-size: 3rem;
  }

  .product-container .container p {
    font-size: 1rem;
  }
}

@media (max-width: 746px) {
  .card__body {
    flex-direction: column;
  }

  .content {
    align-items: center;
    justify-content: center;
  }

  .card-link .card {
    width: 100%;
    min-height: 200px;
    padding: 1rem;
  }

  .img-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .content .title {
    font-size: 1.2rem;
  }

  .content .info {
    font-size: 0.8rem;
  }

  /* .....product section..... */

  .product-container .container {
    position: absolute;
    padding: 2rem;
    z-index: 2;
  }

  .product-container .container {
    padding: 1rem;
  }

  .product-container .container h1 {
    font-size: 2rem;
    font-weight: 700;

  }

  .product-container .container p {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .product-container .container a {
    background: #e11d47;
    transition: all 0.6s;
    text-transform: capitalize;
    padding: 8px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: 400;
  }
}
