/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #111111;
  text-decoration: none;
}

a:hover {
  color: #f43760d4;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #e11d47;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #2d2d2d;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
  height: 170px;
  display: flex;
  align-items: flex-end;
  background-image: url("../public/assets/img/breadcrumb.jpg");
  opacity: 1;
  text-transform: capitalize;
  background-position: left center;
}

.breadcrumbs .container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0 6rem;
}

.breadcrumbs h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 992px) {
  .breadcrumbs {
    height: 120px;
    justify-content: center;
  }

  .breadcrumbs .container {
    margin: 0;
  }

  .breadcrumbs h2 {
    font-size: 20px;
  }
}

.breadcrumbs ol {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 9;
  background: #e11d47;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  background: #ff6385;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # rich text pages
  --------------------------------------------------------------*/

.page {
  padding: 0;
  max-width: 1200px;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-container {
  width: 100%;
  padding: 0 2rem;
}

.page-heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: capitalize;
  color: #2d2d2d;
  text-align: left;
  margin-bottom: 2rem;
}
