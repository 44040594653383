/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #f6f9ff;
  padding: 0 0 30px 0;
  font-size: 14px;
}

.footer .footer-top {
  background: white url(/public/assets/img/footer-bg.png) no-repeat right top;
  background-size: contain;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
}

.footer .footer-top .footer-info .logo img {
  width: 110px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .flags {
  align-items: center;
}

.footer .footer-top .footer-info .flags a {
  display: flex;
  align-items: center;
}
.footer .footer-top .footer-info .flags a:hover {
  color: black;
}

.footer .footer-top .footer-info .flags img {
  width: 30%;
}

.footer .footer-top .footer-info .flags span {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 1rem;
  margin-left: 0.5em;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(156, 0, 34, 0.547);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #e72b54;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2d2d2d;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #2d2d2d;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
}

.footer .footer-top .footer-links ul a:hover {
  color: #e72b54;
}

.footer .footer-top .footer-contact .footer-address {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #2d2d2d;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #2d2d2d;
}
