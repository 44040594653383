/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features .feature-box {
    padding: 24px 20px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
  }
  
  .features .feature-box h3 {
    font-size: 18px;
    color: #2d2d2d;
    font-weight: 700;
    margin: 0;
  }
  
  .features .feature-box i {
    line-height: 0;
    background: #ecf3ff;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 3px;
    transition: 0.3s;
  }
  
  .features .feature-box:hover i {
    background: #e11d47;
    color: #fff;
  }
  
  .features .feture-tabs {
    margin-top: 120px;
  }
  
  .features .feture-tabs h3 {
    color: #2d2d2d;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .features .feture-tabs h3 {
      font-size: 28px;
    }
  }
  
  .features .feture-tabs .nav-pills {
    border-bottom: 1px solid #eee;
  }
  
  .features .feture-tabs .nav-link {
    background: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #2d2d2d;
    padding: 12px 0;
    margin-right: 25px;
    margin-bottom: -2px;
    border-radius: 0;
  }
  
  .features .feture-tabs .nav-link.active {
    color: #e72b54;
    border-bottom: 3px solid #e72b54;
  }
  
  .features .feture-tabs .tab-content h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
    color: #2d2d2d;
  }
  
  .features .feture-tabs .tab-content i {
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
    color: #e72b54;
  }
  
  .features .feature-icons {
    margin-top: 120px;
  }
  
  .features .feature-icons h3 {
    color: #2d2d2d;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .features .feature-icons h3 {
      font-size: 28px;
    }
  }
  
  .features .feature-icons .content .icon-box {
    display: flex;
  }
  
  .features .feature-icons .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #2d2d2d;
  }
  
  .features .feature-icons .content .icon-box i {
    font-size: 44px;
    line-height: 44px;
    color: #e72b54;
    margin-right: 15px;
  }
  
  .features .feature-icons .content .icon-box p {
    font-size: 15px;
    color: #848484;
  }
  