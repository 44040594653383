/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
  background: url(/public/assets/img/hero-bg.png) top center no-repeat;
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #d01f46;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .btn-get-started {
  cursor: pointer;
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #e11d47;
  border: none;
  box-shadow: 0px 5px 30px rgb(225, 29, 71, 0.4);
}

.hero .btn-get-started span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
  background-color: #f6f9ff;
  padding: 40px;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #e11d47;
  text-transform: uppercase;
}

.about h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2d2d2d;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about img {
    width: 100%;
    height: auto;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #e11d47;
  box-shadow: 0px 5px 30px rgb(225, 29, 71, 0.4);
}

.about .btn-read-more span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}