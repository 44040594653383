.careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
}

.careers-container .heading {
  font-size: 3rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.careers-container .desc {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
  padding: 0 4rem;
  text-transform: lowercase;
}

.careers-container .icon-boxes {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.careers-container .icon-boxes .icon-box-container {
  width: 25vw;
  margin-bottom: 2rem;
}

.careers-container .icon-box {
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 5px 29px 0 rgba(18, 66, 101, 0.08);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.careers-container .icon-box:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.careers-container .icon-box .title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1rem;
}

.careers-container .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #e11d47;
}

@media (max-width: 992px) {
  .careers-container .heading {
    font-size: 2rem;
  }

  .careers-container .desc {
    font-size: 1rem;
    padding: 0 3rem;
  }

  .careers-container .icon-boxes {
    margin-top: 2rem;
  }

  .careers-container .icon-boxes .icon-box-container {
    width: 30vw;
    margin-bottom: 2rem;
  }
}

@media (max-width: 746px) {
  .careers-container {
    margin: 0 2rem;
  }

  .careers-container .heading {
    font-size: 2rem;
    font-weight: 700;
  }

  .careers-container .desc {
    font-size: 1rem;
    font-weight: 500;
    padding: 0 1rem;
  }

  .careers-container .icon-boxes {
    margin-top: 2rem;
  }

  .careers-container .icon-boxes .icon-box-container {
    width: 70vw;
    margin-bottom: 1rem;
  }
}
