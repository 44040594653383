/* .loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.main{
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  
  animation: l14 4s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  
}
.loader::before,
.loader::after {    
  content: "";
  background-color: white;
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color:rgb(196, 43, 56) #db454500 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}
.loader::after {
  border-color: rgb(209, 162, 162);
  animation-direction: reverse;
}
@keyframes l14{ 
  100%{transform: rotate(1turn)}
}
